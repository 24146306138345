export const artistsContent = [
  {
    name: "Blume Attempt",
    bio: [
      "Blume Attempt is the artistic name of the Spanish artist based in Leipzig (Germany) Javier Vivancos.",
      "In his second EP «Just like you», Blume Attempt creates a sound of its own where modern electronic experimental music interweaves with strong influences of industrial music from the late 80s.",
      "After a first digital release on Banned In Vegas record label («Perpetual», 2016), Javier Vivancos does a retrospective exercise and projects more aggressive and repetitive sounds and textures in his compositions, passed through his personal filter, leading him to create four new cuts that suppose his first recordings for Hedonic Reversal.",
    ],
    picture: "blume.jpg",
    links: {
      facebook: "https://www.facebook.com/blumeattempt",
      soundcloud: "https://soundcloud.com/blumeattempt",
      instagram: "https://soundcloud.com/blumeattempt",
    },
    releases: [
      {
        id: 6,
        albumId: "2436117798",
        title: "Just Like You",
        catNumber: "HEDREV-00003",
        releaseDate: "23 May 2018",
        format: "Vinyl / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/just-like-you",
        credits: [
          "Written and produced by Blume Attempt",
          "Sax in «Seed» by Hans Betancourth",
          "Mastered by Pedro Viñuela",
          "Art direction and design by Miguel Sueiro",
          "Photography by Sophie Valentin",
        ],
        description: [
          "In his second EP «Just like you», Blume Attempt creates a sound of its own where modern electronic experimental music interweaves with strong influences of the late 80’s industrial music.",
          'After a first digital release on Banned in Vegas record label ("Perpetual", 2016), Javier Vivancos does a retrospective exercise and projects more aggressive and repetitive sounds and textures in his compositions passed through his personal filter, leading him to create four new cuts that involve his first recordings for Hedonic Reversal.',
        ],
        images: [
          "blumeattempt-justlikeyou-01.jpg",
          "blumeattempt-justlikeyou-02.jpg",
          "blumeattempt-justlikeyou-03.jpg",
          "blumeattempt-justlikeyou-04.jpg",
          "blumeattempt-justlikeyou-05.jpg",
          "blumeattempt-justlikeyou-06.jpg",
        ],
        coverImage: "blumeattempt-justlikeyou-cover.jpg",
        listPosition: 11,
      },
    ],
  },
  // ---------------------------------------------------------
 {
    name: "Huma",
    bio: [
      "Huma is the personal project of Andrés Satué. Born in Galicia but based in Barcelona, he has an extensive career in musical experimentation, from his first math-kraut-rock and prog bands, to experimental electronics with his previous project Esquelas and now with Huma. He has released several albums under labels including Bestiarie, ENSMBLE and Matapadre. He participated in the Red Bull Music Academy Bass Camp in Madrid, played at some of the most relevant Spanish experimental electronics festivals and is co-founder of Hedonic Reversal label and Bicefal Festival. He also runs a monthly radio show with his label partner Miguel Sueiro at Dublab Barcelona.",
    ],
    picture: "huma.jpg",
    links: {
      facebook: "https://www.facebook.com/Huma4444",
      soundcloud: "https://soundcloud.com/huma4444",
      instagram: "https://www.instagram.com/huma_4444",
    },
    releases: [
      {
        id: 1,
        albumId: "1635759303",
        title: "Las tres fases del movimiento",
        catNumber: "HEDREV-00001",
        releaseDate: "17 November 2016",
        format: "Vinyl / Digital",
        buyUrl:
          "https://hedonicreversal.bandcamp.com/album/las-tres-fases-del-movimiento",
        credits: [
          "Written and produced by Andrés Satué",
          "Mastered by Pedro Pina",
          "Art direction and design by Miguel Sueiro",
          "© 2016 www.hedonicreversal.com",
        ],
        description: [
          "Huma is the personal project of Andrés Satué, half of the duo Esquelas, project in which they released “Inciso” (Ensmble) and more recently “Un pozo lleno de anzuelos” (Bestiarie). He was participant in the RedBull Muisc Academy BassCamp in Madrid 2015. He is also cofounder of Versal and Bicefal.",
          'This is his first EP as Huma. “Las tres fases del movimiento" is based on two ideas, on one hand the three phases of movement of Meyerhold biomechanics, and on the other hand the three phases of movement in bodybuilding. Joining these two ideas he generates a very physical, visceral and emotional journey.',
          "«A visceral and emotion-laden situation that sometimes seems to set in front of the abyss, but the most important thing is that it generates a movement.» —David Gozálvez, Neonized",
          '"... Huma manages to make his music a purely corporeal act: his cuts are like dancers who move in complex and different ways in space. Sometimes they move as if they want to touch the sky ... » — Fantastic Plastic',
          "«The intersection between extreme, saturation and depth is what describes this new project of Andrés Satué ...» — Frankie Pizá, This Is Underground",
        ],
        images: [
          "huma-tresfases-01.jpg",
          "huma-tresfases-02.jpg",
          "huma-tresfases-03.jpg",
          "huma-tresfases-04.jpg",
          "huma-tresfases-05.jpg",
        ],
        coverImage: "huma-tresfases-cover.jpg",
        listPosition: 13,
      },
      {
        id: 2,
        albumId: "2733975405",
        title: "Yocto / Yotta",
        catNumber: "HEDREV-00002",
        releaseDate: "10 March 2018",
        format: "Vinyl / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/yocto-yotta",
        credits: [
          "Written and produced by Andrés Satué",
          "Mastered by Pedro Pina",
          "Art direction and design by Miguel Sueiro",
          "© 2018 www.hedonicreversal.com",
        ],
        description: [
          "Two new tracks after Humas 2016 first EP «Las tres fases del movimiento",
          "Yocto (symbol y) is a prefix of the International System that indicates a factor of 10-24.",
          "Yotta (symbol Y) is a prefix of the International System that indicates a factor of 1024 (a quadrillion).",
        ],
        images: ["huma-yocto-00.jpg"],
        coverImage: "huma-yocto-cover.jpg",
        listPosition: 12,
      },
      {
        id: 3,
        albumId: "3277444269",
        title: "Eva",
        catNumber: "HEDREV-00006",
        releaseDate: "11 November 2019",
        format: "Cassette / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/eva-2",
        credits: [
          "Written and produced by Andrés Satué",
          "Mastered by Matt Colton at Alchemy Mastering",
          "Art direction and design by Miguel Sueiro",
          "Cover illustration by Martina Ampuero",
          "© 2019 www.hedonicreversal.com",
        ],
        description: [
          "Eva is a journey from the birth of humanity (Adán) to the rebirth of the new human (Eva). With a positive but disturbing point of view it delves into the idea of technological singularity and the consequences of the exponential growth of human development. In order to do so, it explores in an extreme and profound way the relationships between concepts such as micro/macro, organic/inorganic, natural/artificial, man/machine.",
        ],
        images: [
          "huma-eva-01.jpg",
          "huma-eva-02.jpg",
        ],
        coverImage: "huma-eva-cover.jpg",
        listPosition: 8,
      },
      {
        id: 4,
        albumId: "695194201",
        title: "Eva Remixes",
        catNumber: "HEDREV-00006b",
        releaseDate: "26 November 2019",
        format: "Cassette / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/eva-remixes",
        credits: [
          "Written and produced by Andrés Satué",
          "Mastered by Matt Colton at Alchemy Mastering",
          "Art direction and design by Miguel Sueiro",
          "Cover illustration by Martina Ampuero",
          "© 2019 www.hedonicreversal.com",
        ],
        description: [
          "Eva is a journey from the birth of humanity (Adán) to the rebirth of the new human (Eva). With a positive but disturbing point of view it delves into the idea of technological singularity and the consequences of the exponential growth of human development. In order to do so, it explores in an extreme and profound way the relationships between concepts such as micro/macro, organic/inorganic, natural/artificial, man/machine.",
        ],
        images: ["huma-evaremixes-00.jpg"],
        coverImage: "huma-evaremixes-cover.jpg",
        listPosition: 7,
      },
      {
        id: 5,
        albumId: "1673263714",
        title: "Emergence",
        catNumber: "HEDREV-00009",
        releaseDate: "25 February 2021",
        format: "Cassette / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/emergence",
        credits: [
          "Written and produced by Andrés Satué",
          "Mastered by Pedro Pina",
          "Art direction and design by Miguel Sueiro",
          "Cover illustration by Mitrilo",
          "Inner sleeve graphics created with the Wolfram Language (www.wolfram.com)",
          "© 2021 www.hedonicreversal.com",
        ],
        description: [
          "Emergence experiments with irregular and unpredictable structures that arise from sequences and textures colliding and merging into rhythmic and melodic sonic explorations that seem non-human-like but at the same time intuitive and almost familiar. Inspired by the Emergence Theory, which states that a large number of relatively simple elements can self-organize into behaviors that appear to be unexpectedly complex, it creates a process by which musical order seems to emerge out from chaos.",
        ],
        images: ["huma-emergence-00.jpg"],
        coverImage: "huma-emergence-cover.jpg",
        listPosition: 4,
      },

            {
        id: 12,
        albumId: "2904702438",
        title: "se van sus naves",
        catNumber: "HEDREV-00010",
        releaseDate: "19 July 2021",
        format: "Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/se-van-sus-naves",
        credits: [
          "Music by Andrés Satué",
          "Art direction and design by Miguel Sueiro",
          "Cover Illustration Mitrilo Collective",
          "© 2022 www.hedonicreversal.com",
        ],
        description: [
          "«Se van sus naves» is an EP of experiments with different creative processes and tools such as granular synthesis or extreme sampling, created halfway between «Eva» and «Emergence».",
          "Continuing with the exploration of the idea of singularity and exponential growth in which «Eva» was inspired, «Se van sus naves» proposes an abstract and delicate universe, closer to ambient but without leaving aside the characteristic abrasion of other albums.",
        ],
        images: ["huma-sevansusnaves-00.jpg"],
        coverImage: "huma-sevansusnaves-cover.jpg",
        listPosition: 3,
      },
      {
        id: 13,
        albumId: "1241658922",
        title: "Hyperchaotic",
        catNumber: "HEDREV-00012",
        releaseDate: "01 April 2022",
        format: "Cassette / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/hyperchaotic",
        credits: [
          "Written, produced and mastered by Andrés Satué",
          "Art direction and design by Miguel Sueiro",
          "Cover illustration by Jos Leys",
          "© 2022 www.hedonicreversal.com",
        ],
        description: [
          "Huma «Hyperchaotic» explores chaos, theoretical physics, uncertainties and how order emerges out of the fog of the real. No apparent or understandable reason is required for this or anything else to simply, be.",
          "The album seeks balance between randomness and certainty, between abrasion and delicacy. Sound design references elemental processes that seem to take on a life of their own: combining, interfacing and modulating each other. Sound, space and silence creating symbiosis and an incredibly detailed listening experience which evokes the kinesthetics of Gábor Lázár or the cybernetic wild cards of Terminal 11.",
          "The album is released at Opal Tapes in collaboration with Hedonic Reversal - hedonicreversal.bandcamp.com - Where cassette tape versions of this release can be found.",
        ],
        images: ["huma-hyperchaotic-00.jpg"],
        coverImage: "huma-hyperchaotic-cover.jpg",
        listPosition: 1,
      },
    ],
  },
  // -------------------------------------------------------
  {
    name: "IOM",
    bio: [
      "Iker Ormazabal was born in Vitoria and lives in London. He started his solo project IOM circa 2004. He has also been a member of bands like Atomoog, Sorkun, FFT Players, Soizu, Oilbag Mnemoniic o Warren Schoenbright and has published music in labels such as: Zulo Beltzak, RONF, Doministiku, Desetxea, Afeite Al Perro, Astral Noize, Attenuation Circuit, Mattoid and Hominid Sounds.",
      'He has previously worked alongside other artists such as Arturo Blasco, Jordi Aligué, Borja Ramos, Miguel A García, Raxil4, Itto Morita, Minako Seki, Gonzalo Catalinas among others. In 2017, he was awarded with the John Leckie Award for Innovation in Audio Production for a soundtrack to "Elementary Tryptych of Spain" by Jose Val del Omar. He also runs his own effects-pedal brand, IOHM Fx.',
    ],
    picture: "iom.jpg",
    links: {
      bandcamp: "https://ikerormazabal.bandcamp.com",
    },
    releases: [
      {
        id: 10,
        albumId: "2695128972",
        title:
          "Izkuturik ziren hitzak eztabaiden artean aurkitu ditut, baino soinurik ez dute egiten",
        catNumber: "HEDREV-00007",
        releaseDate: "10 December 2020",
        format: "Cassette / Digital",
        buyUrl:
          "https://hedonicreversal.bandcamp.com/album/izkuturik-ziren-hitzak-eztabaiden-artean-aurkitu-ditut-baino-soinurik-ez-dute-egiten",
        credits: [
          "Iker Ormazabal: Arturia Mini Brute, Sherman Filterbank, Elektron Octatrack, Trogotronic 669 and several pedals from IOhM Fx.",
          "Recorded in Ableton Live and mixed in Pro Tools.",
          "Miguel Sueiro: Art direction & design.",
          "Teresa Espadafor: Photography.",
          "© 2020 www.hedonicreversal.com",
        ],
        description: [
          "Time is the main subject matter in IOMs music. Kronos and Kairos are the two greek words which describe its different facets. Kronos refers to the chronological character and its human perception, while the latter refers to the propitious moment and the permanent condition of time. My main interest, is the intersection between these two characteristics in the present moment.",
          "In this album, specifically, there is a fundamental structure in each song, which was recorded in one take and represents the sequential aspect. The rest of the tracks building-blocks are based on studio processes and a posteriori decisions, which in turn represent the vertical quality of time and the present moment in each of the albums brief moments.",
        ],
        images: ["iom-izkuturik-00.jpg"],
        coverImage: "iom-izkuturik-cover.jpg",
        listPosition: 6,
      },
    ],
  },
  // -------------------------------------------------------  
{
    name: "Noir Noir / Vesakh",
    bio: [
      "NOIR NOIR is David Vellvehi Galindo, from Mataró, near Barcelona. His sound is a mix between industrial, experimental electronics and the most disturbing dark ambient. Released by labels such as Triangulum Ignis, Fallow Field, Màgia Roja, Marbre Negre, and IIIARMS in the different stages of the project. Throughout the time, his activity has been mutating from his more intense and noisy beginnings, to more rhythmic and threatening scenarios. He shares in his songs a dreamlike and dark imaginary, with vocals and guitars that refer to a certain conceptual expressionism of Black Metal, suffocating synthesizers and modified field recordings.",
      "He has participated in different festivals (Sonar, Bicefal, Death Cult Rising, Teorema, WAV...), he has also been regularly presenting all the material he has released in live concerts in different venues and events nationwide.",
      "In the last stage he has participated in collaborative projects, along with other artists of more experimental orbit (SCMUTE) to perform live and more abrupt and artistic interventions with improvisation as the axis. In his latest solo works, he develops a more ritual and intense concept, on mystical themes and death as a transforming element.",      
      "Vesakh is a death electronics project begotten in absolute anonymity and supreme conceptual asceticism in MMXIX in order to provide uniquely harsh and vomitive noise music as a monolithic negation of all existence.",            
    ],
    picture: "noirnoir.jpg",
    links: {
      bandcamp: "https://noir-noir.bandcamp.com",
      instagram: "https://www.instagram.com/treblenoir/",
    },
    releases: [
      {
        id: 14,
        albumId: "3412546295",
        title: "Divine Swelling",
        catNumber: "HEDREV-00011",
        releaseDate: "10 March 2022",
        format: "Cassette / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/divine-swelling",
        credits: [
          "Written and produced by David Vellvehi &Vesakh",
          "Cover illustration by David Vellvehi",
          "Artwork by Miguel Sueiro",
          "© 2022 www.hedonicreversal.com",
        ],
        description: [
          "In poisonous alliance we proudly present Noir Noir and Vesakh new split release. Ten cuts of extreme electronics ritual offence.",
          "On the one hand, Vesakh provides four tracks of grotesque and ill-formed death electronics extravagance. On the other hand, Noir Noir returns again with six unusually aphotic and detrimental songs following the path of the vulture. Only for the power electronics and death industrial devotee.",
        ],
        images: [
          "noirnoir-divineswelling-00.jpg",
        ],
        coverImage: "noirnoir-divineswelling-cover.jpg",
        listPosition: 2,
      },
    ],
  },
  // -------------------------------------------------------    
 {
    name: "Orphan Swords",
    bio: [
      "Brussels based duo Orphan Swords have made a name for themselves, specialising in formal radicalism and brutal-yet-danceable live performances. Yet, defining their project and vision for several years, Pierre de Mûelenaere (Maze & Lindholm) and Yannick Franck (RAUM, Mt Gemini, Figure Section) have, in this, explored a multitude of sounds and styles, delivering heavy industrial pulses, dark trance, post-wave, throbbing live rave techno and deep introspective soundscapes. Their music has been released on a.o., Aurora Borealis, Desire Records and Clan Destine Records.",
      "Ever refined yet never for the faint hearted, their music is at once conceptually rigorous and powerfully presented. As well as numerous projects, releases and aesthetic directions, the duo have  also developed several multimedia collaborations, including a performance for the notorious Antwerp designer Walter Van Beirendonck and his 2018 show at Paris Fashion Week. ‘A collection that was both eye-opening and ear stimulating, thanks to the mutedly nosebleed soundtrack delivered by Orphan Swords’ said Vogue.",
    ],
    picture: "orphan-swords.jpg",
    links: {
      facebook: "https://www.facebook.com/OrphanSwords/",
      soundcloud: "https://soundcloud.com/orphanswords",
      instagram: "https://www.instagram.com/orphanswords/",
    },
    releases: [
      {
        id: 9,
        albumId: "2966684029",
        title: "Ascent",
        catNumber: "HEDREV-00005",
        releaseDate: "21 June 2019",
        format: "Vinyl / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/ascent",
        credits: [
          "Music by Orphan Swords ",
          "Mastered by Pedro Pina",
          "Artwork and design by Miguel Sueiro",
          "© 2019 www.hedonicreversal.com",
        ],
        description: [
          "After several acclaimed EPs, including Risk in a New Age(Desire Records 2014), License To Desire (Desire Records 2015, reissued by Aurora Borealis recordings in 2017) andWeehawken (Clan Destine Records 2016) - Orphan Swords’ debut LP, Ascent, finally arrives. In recording the album, the duo took the radical decision to interchange their usual respective input in the creative process, which resulted in a revelatory piece of music, one that functions as an initiatory narrative summarized as follows:",
          "Ascent is the story of an enigma vanishing into a mountain, somewhere undefined. You left your home long ago but still you endeavor to solve this mystery. At last, today you reach the highest town in the mountains, the ultimate refuge before the loftiest peak. You prepare yourself for the culmination of your search, the ascent. As you climb, the path gets steeper and steeper, the air thinner and thinner. Exhausted and out of breath, you push on. Surrounded by clouds and ice-cold stone, through the haze of your confusion, you’re struck by the thought that the object of your obsession, the missing entity you’re driven to find, is a part of you. It was lost long ago along with every memory you had of its form and shape. Nevertheless you know for sure it’s what you seek. ",
          "Ascent is the story of that search for inner unity, through transience, emptiness, and finally, acceptance. Through profound introspection we ascend.",
          "Ascent is the first part of a diptych. Breach - also recorded during the same period - will follow and conclude the two-part conceptual series at a later date.",
        ],
        images: [
          "orphanswords-ascent-01.jpg",
          "orphanswords-ascent-02.jpg",
          "orphanswords-ascent-03.jpg",
          "orphanswords-ascent-04.jpg",
          "orphanswords-ascent-05.jpg",
          "orphanswords-ascent-06.jpg",
        ],
        coverImage: "orphanswords-ascent-cover.jpg",
        listPosition: 9,
      },
    ],
  },
  // -------------------------------------------------------  
  {
    name: "Refectori",
    bio: [
      "Refectori (Xavier Longàs) has performed in different halls and festivals in Barcelona as Bicefal festival, also in the third and fourth WAV Conference that were celebrated in El Pumarejo and in Hangar respectively, likewise took sounds next to techno in Kreuzberg Club organized by Diffuse Reality and with the HEX collective has participated in his second episode in The Loft of Razzmatazz.",
    ],
    picture: "refectori.jpg",
    links: {
      facebook: "https://www.facebook.com/rrefectori/",
      soundcloud: "https://soundcloud.com/refectori",
      instagram: "https://www.instagram.com/refectori/",
    },
    releases: [
      {
        id: 8,
        albumId: "466129291",
        title: "Espleni",
        catNumber: "HEDREV-00004",
        releaseDate: "05 April 2019",
        format: "Vinyl / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/espleni",
        credits: [
          "Produced by Xavier Longàs",
          "Mastered by Pedro Pina",
          "Art direction & Design Miguel Sueiro",
          "Photograhpy by Eva Paià",
          "© 2019 www.hedonicreversal.com",
        ],
        description: [
          "From the physical transformation of organic elements that melt generating new textures, Espleni is created. Fragmented into nine pieces, it starts with an inmersive melody starting a route without return where you can explore frozen territories of heavy structures. This reflects the union between body and raw material, an interest that was already glimpsed in its first release with Bestiarie (2015). The distortion of organic sounds arrives to wide landscapes of noise, sometimes moderated and in others uncontrolled. Noises that are juxtaposed in a constant dialogue until its definitive bifurcation.",
          "This project starts from the recording of everyday sounds that surround the author, using a Zoom H5 recorder, with which he later experiments, manipulating and gathering them until creating a musically harmonic composition.",
          "He has performed in different halls and festivals in Barcelona as Bicefal festival, also in the third and fourth WAV Conference that were celebrated in El Pumarejo and in Hangar respectively, likewise took sounds next to techno in Kreuzberg Club organized by Diffuse Reality and with the HEX collective has participated in his second episode in The Loft of Razzmatazz.",
        ],
        images: [
          "refectori-espleni-01.jpg",
          "refectori-espleni-02.jpg",
          "refectori-espleni-03.jpg",
          "refectori-espleni-04.jpg",
          "refectori-espleni-05.jpg",
        ],
        coverImage: "refectori-espleni-cover.jpg",
        listPosition: 10,
      },
  {
        id: 14,
        albumId: "731044038",
        title: "Tundra",
        catNumber: "HEDREV-00008",
        releaseDate: "28 January 2021",
        format: "Cassette / Digital",
        buyUrl: "https://hedonicreversal.bandcamp.com/album/tundra",
        credits: [
          "Written and produced by Xavier Longàs",
          "Mastered by Pedro Pina",
          "Art direction & Design Miguel Sueiro",
          "Photography by Júlia Aguilar",
          "© 2021 www.hedonicreversal.com",
        ],
        description: [
          "Refectori is the project of the young catalan Xavier Longàs. Based on the experimental sound, that highlight physical and voluminous forms that roam glacial landscapes. Diving through dense and icy seas, it slowly emerges into light, encountering the earthly world between reverberated voices and nostalgic melodies.",
          "He has performed in different concert halls and festivals held in Barcelona such as MIRA Festival, in the two editions of Bicefal Festival, as well as bringing sounds close to techno at the Kreuzberg Club by the hand of Diffuse Reality. Also with the HEX collective, he has participated in his second episode in Razzmatazz's The Loft.",
          "Tundra takes by inspiration the northern hemisphere. It starts from the southern area of that hemisphere to the Northern area of the whole globe, where all the nature mutates by the clime and changes slowly its form. It’s about a life path where our humanity starts blurring with what the earthly world is made from devoured by organic sounds that take on a variety contrasted by the natural and the artificial.",
        ],
        images: [
          "refectori-tundra-00.jpg",
        ],
        coverImage: "refectori-tundra-cover.jpg",
        listPosition: 5,
      },      
    ],
  },
  // --------------------------------------------------------

];
